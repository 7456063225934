import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import { ActionIcon, Divider, Menu, Title } from "@mantine/core";
import {
  createStyles,
  Table,
  Progress,
  Anchor,
  Text,
  Group,
  ScrollArea,
  SimpleGrid,
  Button,
  Chip,
  Badge,
} from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useCellRender } from "../hooks/useCellRender";

import {
  IconUsers,
  IconUser,
  IconCircleCheck,
  IconHourglass,
  IconNotes,
  IconTableImport,
  IconCirclePlus,
  IconUserCircle,
  IconBuildingBank,
  IconCircleMinus,
  IconActivity,
  IconCircleX,
  IconHeart,
  IconReceipt2,
  IconAlertCircle,
  IconDots,
  IconDotsVertical,
  IconRefresh,
} from "@tabler/icons";

import CardStat from "./cardStat";
import { showNotification } from "@mantine/notifications";
import InfoGridList from "./infoGridList";

const OrderCommissionPaymentSummary = ({ form }) => {
  const { _id: orderId, status, payment, bidConsultants } = form.values;
  const isCertainPayment = _.get(form.values, "isCertainPayment");
  const [api] = useServerApi();
  const [fetching, setFetching] = useState(false);
  const [formatter] = useFormatter();
  const [cellRender] = useCellRender();

  const [infoData, setInfoData] = useState({});

  //Fetch Commission Payment for the order if exist
  // const fetch = async () => {
  //   if (!orderId) return;
  //   try {
  //     setFetching(true);
  //     const data = await api.Order.getCommissionPayment(orderId);
  //     console.log("getCommissionPayment", data);

  //     setInfoData({
  //       fyPremium: payment.yearPremium ?? emptyStr,
  //       fyRate: payment.yearRate ?? emptyStr,
  //       fyConsultantCommission: payment.yearConsultantCommission ?? emptyStr,
  //       fyCommission: payment.yearCommission ?? emptyStr,
  //       baseCurrency: payment?.currency,
  //       bidConsultants,
  //     });

  //     setFetching(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetch();
  // }, [orderId]);

  const emptyStr = "--";

  const infoFields = [
    {
      title: "First Year Premium",
      value: formatter.currency(payment.yearPremium),
    },
    { title: "First Year Rate", value: formatter.percentage(payment.yearRate) },
    {
      title: "First Year Commission (Total)",
      value: formatter.currency(payment.yearCommission),
    },
    {
      title: "First Year Commission (Consultant)",
      value: formatter.currency(payment.yearConsultantCommission),
    },
    {
      title: "Base Currency",
      value: payment?.currency,
    },
    {
      title: "Bid Consultants",
      value: cellRender.Users(bidConsultants),
    },
  ];

  return (
    <>
      <Group position="apart" mb="md" pb="0">
        <Group spacing={1}>
          <Badge radius={0}>
            V{(form.values?.commission?.table?.index ?? 0) + 1}
          </Badge>
          <Badge color={isCertainPayment ? "green" : "red"} radius={0}>
            {isCertainPayment ? "Certain" : "Un Certain"}
          </Badge>
        </Group>
        {/* <ActionIcon
          onClick={() => {
            form.reload();
          }}
        >
          <IconRefresh size={16} stroke={1.5} />
        </ActionIcon> */}
      </Group>
      <InfoGridList fields={infoFields} values={infoData} />
    </>
  );
};

export default OrderCommissionPaymentSummary;
